/**
 * @typedef {import('gatsby-plugin-image').IGatsbyImageData} ImageData
 * @typedef {Readonly<{ alt: string, buttonText: string, buttonUrl: string, className: string, content: string, image: ImageData , title: string }>} Props
 */
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from 'components/shared/Button';
import PropTypes from 'prop-types';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class ImageSection extends React.Component {
	render() {
		return (
			<section
				className={`${this.props.className} grid grid-cols-1 md:grid-cols-2 gap-24 items-center mb-12`}
			>
				<header className="flex flex-col items-start">
					<h3 className="font-semibold mb-8 text-3xl">
						{this.props.title}
					</h3>
					<p className="font-semibold mb-12 text-color-text-primary">
						{this.props.content}
					</p>
					<Button
						backgroundColor="white"
						className="border border-primary hover:bg-primary hover:text-white transition-bg transition-text ease-in-out"
						url={this.props.buttonUrl}
						text={this.props.buttonText}
					/>
				</header>
				<figure className="row-start-1 md:col-start-2">
					<GatsbyImage
						alt={this.props.alt}
						image={this.props.image}
					/>
				</figure>
			</section>
		);
	}
}

ImageSection.propTypes = {
	alt: PropTypes.string,
	buttonUrl: PropTypes.string,
	buttonText: PropTypes.string,
	className: PropTypes.string,
	content: PropTypes.string,
	image: PropTypes.object,
	title: PropTypes.string,
};
