/**
 * @typedef {import('../components/shared/PageQuery').default} PageQuery
 * @typedef {import('gatsby-plugin-image').IGatsbyImageData} ImageData
 * @typedef {React.PropsWithChildren<PageQuery>} Props
 */
import { graphql } from 'gatsby';
import content from 'content/content.json';
import Hero from 'components/shared/Hero';
import imageList, { findImage } from '../config/images';
import ImageSection from 'components/home/ImageSection';
import Layout from 'components/Layout';
import paths from '../config/paths';
import PropTypes from 'prop-types';
import React from 'react';
import seo from 'config/seo.json';

// TODO: Split into Homeowner Route
/** @extends {React.Component<Props>} */
export default class Home extends React.Component {
	/**
	 * @private
	 * @param {keyof imageList} key
	 * @returns {ImageData | null}
	 */
	image(key) {
		return findImage(imageList[key].path, this.props.data.allFile.edges);
	}

	render() {
		return (
			<Layout title={seo.home.title} description={seo.home.description}>
				<Hero
					alt={imageList.home.alt}
					buttonText={content.pages.home.hero.buttonText}
					buttonUrl={paths.register.base}
					content={content.pages.home.hero.content}
					image={this.image('home')}
					isExternalLink={true}
					title={content.pages.home.hero.title}
				/>
				<section className="bg-secondary bg-opacity-10">
					<div className="flex flex-col items-center max-w-7xl mx-auto px-8 lg:px-16 py-24">
						<h2 className="mb-8 text-color-text-primary text-xl">
							{content.pages.home.services.title}
						</h2>
						<p className="font-semibold text-5xl">
							{content.pages.home.services.content}
						</p>
					</div>
				</section>
				<section className="max-w-7xl mx-auto px-8 lg:px-16 pt-4-24">
					{['agents', 'lenders', 'homeowners', 'franchise'].map(
						/**
						 * @param {'agents'|'franchise'|'homeowners'|'lenders'} section
						 * @returns {Element}
						 */
						// @ts-ignore
						(section) => (
							<ImageSection
								alt={imageList[section].alt}
								buttonText={content.pages.home[section].button}
								buttonUrl={paths[section]}
								className="mb-24 lg:mb-48"
								content={content.pages.home[section].content}
								image={this.image(section)}
								key={section}
								title={content.pages.home[section].title}
							/>
						)
					)}
				</section>
			</Layout>
		);
	}
}
Home.propTypes = {
	data: PropTypes.string,
};

export const pageQuery = graphql`
	{
		allFile {
			edges {
				node {
					childImageSharp {
						gatsbyImageData
					}
					id
					relativePath
				}
			}
		}
	}
`;
